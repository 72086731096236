const createError = (name: string) => {
  return class extends Error {
    constructor(message: string) {
      super(message);
      this.name = name;
    }
  }
}

export const UnauthenticatedError = createError('UnauthenticatedError');
