export enum LikeType {
  Like = 1,
  Dislike = -1,
}

export interface Like {
  id: string;
  userId: string;
  establishmentId: string;
  type: LikeType;
}
