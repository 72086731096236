export enum TrackingEvent {
  Like = 'like',
  Dislike = 'dislike',
  Unlike = 'unlike',
  Call = 'call',
  Share = 'share',
  Save = 'save',
  Unsave = 'unsave',
  View = 'view',
  Appear = 'appear',
}
