import { NgModule } from '@angular/core';
import { PreloadAllModules, Router, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule),
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./pages/sign-up/sign-up.module').then( m => m.SignUpPageModule),
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule),
  },
  {
    path: 'quick-choice',
    loadChildren: () => import('./pages/quick-choice/quick-choice.module').then( m => m.QuickChoicePageModule),
  },
  {
    path: 'eat',
    loadChildren: () => import('./pages/eat/eat.module').then( m => m.EatPageModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule),
  },
  {
    path: 'profile-edit',
    loadChildren: () => import('./mobile/profile-edit/profile-edit.module').then( m => m.ProfileEditPageModule),
  },
  {
    path: 'restaurant',
    loadChildren: () => import('./modals/restaurant/restaurant.module').then( m => m.RestaurantPageModule)
  },
  {
    path:'restaurant_page/:id',
    loadChildren: () => import('./pages/restaurantpage/restaurantpage.module').then(m => m.RestaurantpagePageModule)
  },
  {
    path: 'restaurant-modal',
    loadChildren: () => import('./restaurant-modal/restaurant-modal.module').then( m => m.RestaurantModalPageModule)
  },
  {
    path: 'menu',
    loadChildren: () => import('./modals/menu/menu.module').then( m => m.MenuPageModule)
  },
  {
    path: 'saved',
    loadChildren: () => import('./pages/saved/saved.module').then( m => m.SavedPageModule)
  },
  {
    path: 'bookings',
    loadChildren: () => import('./pages/bookings/bookings.module').then( m => m.BookingsPageModule)
  },
  {
    path: 'category/:id',
    loadChildren: () => import('./pages/category/category.module').then( m => m.CategoryPageModule)
  },
  {
    path: 'restaurantpage',
    loadChildren: () => import('./pages/restaurantpage/restaurantpage.module').then( m => m.RestaurantpagePageModule)
  }

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(public router: Router){

    let loadRoute = this.router.url.split('/');

    console.log('loadRoute', loadRoute);

    if(loadRoute.includes('restaurant_page')){
      //alert('restaurant_page');
    }

  }
}
