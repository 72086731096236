import { register } from 'swiper/element/bundle';
import { Component } from '@angular/core';
import { TrackingService } from './services';
import { Router } from '@angular/router';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  page = 'home'
  showSidemenu = false;

  modal = '';

  constructor(public router: Router) {

    if(!localStorage.getItem('menus')){
      localStorage.setItem('menus','[]')
    }

    router.events.subscribe(val => {
      let route = this.router.url.split('/');
      let lastRoute = route[route.length - 1];
      this.page = lastRoute;
      if (lastRoute != '') {
        if (
          lastRoute == 'sign-up' ||
          lastRoute == 'login' ||
          lastRoute == 'registration' ||
          lastRoute == 'reset'
        ) {
          this.showSidemenu = false;
        } else {
          this.showSidemenu = true;
        }
      }

      // a1cc5262-557a-465a-afbc-43ecc8ac517e
    })
  }
}

