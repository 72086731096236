import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Establishment } from 'src/app/models';
import { Booking } from 'src/app/models/booking';
import { ApiService } from 'src/app/services';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.page.html',
  styleUrls: ['./booking.page.scss'],
})
export class BookingPage implements OnInit {
  booking!:Booking;
  restaurant:Establishment;

  constructor(private apiService:ApiService, public modalCtrl:ModalController) { }

  ngOnInit() {
  }

  ionViewWillEnter(){
    this.apiService.getEstablishment(this.booking.establishment.id).subscribe(restaurant => {
      this.restaurant = restaurant;
    })
  }

  call(){
    window.open('tel:'+this.restaurant.phone, '_system');
  }

}
