import { Observable, BehaviorSubject, map, filter } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { AuthenticatorServiceFacade, AmplifyUser } from '@aws-amplify/ui';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private auth$ = new BehaviorSubject<AuthenticatorServiceFacade>(null);

  public user$: Observable<AmplifyUser> = this.auth$.pipe(
    filter(val => !!val),
    map(auth => auth.user),
  );

  public accessToken$: Observable<string> = this.auth$.pipe(
    filter(val => !!val),
    map(auth => {
      if (!auth.user) {
        return '';
      }
      return auth.user.getSignInUserSession().getAccessToken().getJwtToken();
    }),
  );

  constructor(
    private authenticatorService: AuthenticatorService,
  ) {
    this.init();
  }

  private init(): void {
    this.authenticatorService.subscribe(auth => {
      this.auth$.next(auth);
    });
  }
}
