import { Cache } from '@aws-amplify/cache';
import { Injectable } from '@angular/core';

import { CacheOptions } from './models';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  setItem<T>(key: string, value: T, options: CacheOptions = {}): void {
    const { ttl, expiresAt, priority } = options;
    
    let expires: number = null;
    if (expiresAt) {
      expires = expiresAt.getTime();
    } else if(ttl) {
      expires = new Date().setMilliseconds(new Date().getMilliseconds() + ttl);
    }

    Cache.setItem(key, value, {
      ...(!!priority && { priority }),
      ...(!!expires && { expires }),
    });
  }

  getItem<T>(key: string): T {
    return Cache.getItem(key);
  }

  removeItem(key: string): void {
    Cache.removeItem(key);
  }
}
