import { Observable, mergeMap, map, iif, defer, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Establishment, FeedCategory, LikeType, MyListItem, UnauthenticatedError } from '../../models';
import { AuthService } from '../auth';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';

const HOME_FEED_URL = '/feed';
const LIKE_URL = '/likes/like';
const DISLIKE_URL = '/likes/dislike';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {
    loader: HTMLIonLoadingElement;

    constructor(
        public toastCtrl: ToastController,
        public alertCtrl: AlertController,
        public loadingCtrl: LoadingController,
    ) { }

    async startLoader(text: string = '', duration: number = 60000) {
        this.loader = await (this.loadingCtrl.create({ message: text, duration: duration }));
        this.loader.present();
    }

    async showAlert(header: string = '', message: string = '') {
        await (
            await this.alertCtrl.create({
                header:header,
                message:message,
                buttons:["OK"]
            })
        ).present();
    }

    async updateUrl(id) {
        window.location.href = 'http://locahost:8100/restaurant/' + id;
    }

}