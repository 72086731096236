import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Establishment } from 'src/app/models';
import { ApiService } from 'src/app/services';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.page.html',
  styleUrls: ['./menu.page.scss'],
})
export class MenuPage implements OnInit {
  menu: any = [];
  restaurant!: Establishment;
  selectedSection = { id: '', name: '', items: [] };
  showIndex = 0;

  constructor(private apiService: ApiService, public modalCtrl:ModalController) {
  }

  ionViewDidEnter() {
    this.selectedSection = this.menu.sections[0];
  }

  ngOnInit() {
  }

  selectSection(section) {
    this.showIndex = 0;
    this.selectedSection = section;
  }

}
