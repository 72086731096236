// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  mapsKey: 'AIzaSyCZ6C5bxn8SfyKO7IatjhIAwnESkFs8VrQ',
  apiHostUrl: 'https://api.dev.restaapp.com',
  staticHostUrl: 'https://static.dev.restaapp.com',
  amplify: {
    Auth: {
      identityPoolId: 'eu-west-1:2839bbb6-ecd1-48cd-80ab-7a72e8069b9c',
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_wmUr2J9Pp',
      userPoolWebClientId: '1p2t08ak8f87s9v3uh6f3lavcn',
      mandatorySignIn: false,
    }, 
    Analytics: {
      disabled: false,
      autoSessionRecord: true,
      AWSPinpoint: {
        region: 'eu-west-1',
        appId: '9115b9ecf84c472d95cd65a956d5b580',
      },
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
