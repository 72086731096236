import { Amplify } from '@aws-amplify/core';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { CreateBookingPageModule } from './modals/create-booking/create-booking.module';
import { MenuPageModule } from './modals/menu/menu.module';
import { RestaurantPageModule } from './modals/restaurant/restaurant.module';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { BookingPageModule } from './modals/booking/booking.module';


@NgModule({
  declarations: [
    AppComponent,    
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    RestaurantPageModule,
    MenuPageModule,
    CreateBookingPageModule,
    BookingPageModule
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, Geolocation],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    Amplify.configure(environment.amplify);
  }
}
