import { Establishment } from './';

export enum MyListAction {
  Like = 'like',
  Dislike = 'dislike',
  Save = 'save',
}

interface Comment{
  message:string
}

export interface MyListItem {
  actionId:string,
  comment:Comment,
  establishment: Establishment;
  action: MyListAction;
  createdAt: string;
}
