import { ElementRef, Injectable } from '@angular/core';
import { Observable, Subject, filter, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IntersectionObserverService {
  private subject$ = new Subject<IntersectionObserverEntry>();
  private observer: IntersectionObserver;

  constructor() {
    this.init();
  }

  observe(elementRef: ElementRef): Observable<boolean> {
    const { nativeElement } = elementRef;

    this.observer.observe(nativeElement);
    return this.subject$.asObservable()
      .pipe(
        filter(({ target }: IntersectionObserverEntry) => target === nativeElement),
        map(({ isIntersecting }: IntersectionObserverEntry) => isIntersecting),
      );
  }

  unobserve(elementRef: ElementRef): void {
    this.observer.unobserve(elementRef.nativeElement);
  }

  private init(): void {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.9,
    };

    this.observer = new IntersectionObserver(this.handleIntersection.bind(this), options);
  }

  private handleIntersection(entries: IntersectionObserverEntry[]): void {
    entries.forEach(entry => this.subject$.next(entry));
  }
}
